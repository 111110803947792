// @ts-strict-ignore
import { MinimalProfile, Person, Profile } from "types/person"

export type NamedPerson = Profile | Person | MinimalProfile

export function getPersonName(person: NamedPerson): string {
  if (person) {
    if (person.preferredName) {
      return person.preferredName
    } else if (person.name) {
      return person.name
    } else if ("email" in person) {
      // `email` is not in the `MinimalProfile` type
      return person.email
    } else {
      return person.uid
    }
  }
}

/**
 * Returns a shortened version of a person's name that's unambiguous within the given group.
 * Will return:
 * - First name only if unique
 * - First name + last initial if needed
 * - Full name if the above are ambiguous
 */
export function getShortName(allUsers: Profile[], person: Profile): string {
  const fullName = getPersonName(person)

  // If person isn't in the group, return full name
  if (!allUsers.some((u) => u.uid === person.uid)) {
    return fullName
  }

  // Try first name only
  const firstName = getFirstName(fullName)
  const usersWithSameFirstName = allUsers.filter(
    (u) => getFirstName(getPersonName(u)) === firstName
  )
  if (usersWithSameFirstName.length === 1) {
    return firstName
  }

  // Try first name + last initial
  const firstNameWithLastInitial = getFirstNameWithLastInitial(fullName)
  const usersWithSameFirstNameWithLastInitial = allUsers.filter((u) => {
    const name = getPersonName(u)
    const initial = getFirstNameWithLastInitial(name)
    return initial === firstNameWithLastInitial
  })
  if (usersWithSameFirstNameWithLastInitial.length === 1) {
    return firstNameWithLastInitial
  }

  // Fall back to full name if still ambiguous
  return fullName
}

function getFirstName(name: string) {
  return name.match(/^\S+/)?.[0]
}

function getFirstNameWithLastInitial(name: string) {
  const match = name.match(/^\S+\s+\S/)
  return match ? `${match[0]}.` : name
}
