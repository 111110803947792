// @ts-strict-ignore
import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"

import { Auth } from "@aws-amplify/auth/lib/Auth"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { Basket } from "types/basket"
import { Pass } from "types/pass"
import { Balance, Profile, UserGroup } from "types/person"
import { QuoteType } from "types/quote"
import { SearchParamsType } from "types/search"

import { bookingFromDate } from "./date-utils"
import { fetchFromAPIBase } from "./fetch-utils"
import { setGlobalDispatch, setGlobalState } from "./global-state"
import { getPersonName } from "./name-utils"

// Make available for tests
if (typeof window !== "undefined" && window["Cypress"]) {
  window["authModule"] = Auth
}

const queryClient = new QueryClient()

const stateLogger = (state, action, nextState) => {
  const table = {}
  Object.entries(state).map((item) => {
    table[item[0]] = {
      prevState: typeof item[1] == "object" ? JSON.stringify(item[1]) : item[1],
    }
  })
  Object.entries(nextState).map((item) => {
    table[item[0]].nextState =
      typeof item[1] == "object" ? JSON.stringify(item[1]) : item[1]
  })

  if (
    (typeof window !== "undefined" && window["Cypress"]) ||
    (typeof process !== "undefined" &&
      JSON.parse(process.env.GATSBY_DEBUG ?? ""))
  ) {
    console.group(
      "%cAction",
      "color:#00a8f7;font-size:1.3em;font-weight:600;",
      action
    )
    console.table(table)
    console.log(
      "%cprev state",
      "color:grey;font-size:1.1em;font-weight:600;",
      state
    )
    console.log(
      "%cnext state",
      "color:#47b14b;font-size:1.1em;font-weight:600;",
      nextState
    )

    console.groupEnd()
  }
}

export type StateType = {
  didUserScroll: boolean

  // Search bar Parameters

  searchbarIsCollapsed: boolean
  searchParams: SearchParamsType
  searchModified: boolean

  // Book Page Parameters

  outboundQuotes: QuoteType[]
  returnQuotes: QuoteType[]
  outboundBasket: Basket
  outboundBasketOrigin: number
  outboundBasketDestination: number
  returnBasket: Basket
  returnBasketOrigin: number
  returnBasketDestination: number
  initialOrder: Pass[]
  initialOrderUid: string

  // Customer account

  openLoginResultDialog: boolean
  loginErrorMessage: string

  shouldRequestAccount: boolean
  fetchingAccount: boolean

  loggedIn: boolean
  groups: UserGroup[]
  loggedInPersonUid?: string

  profile: Profile
  balance: Balance
}

const reducer = (
  state: StateType,
  action: Partial<StateType> | string
): StateType => {
  const nextState = Object.assign({}, state)
  Object.keys(action).forEach((key) => {
    nextState[key] = action[key]
  })
  stateLogger(state, action, nextState)
  return nextState
}

export const GlobalStateContext = createContext({} as StateType)
const GlobalDispatchContext = createContext({} as Dispatch<any>)

export const useGlobalState = () => useContext(GlobalStateContext)
export const useGlobalDispatch = () => useContext(GlobalDispatchContext)
export const useGlobalSetter = (name) => {
  const dispatch = useGlobalDispatch()
  return (value) => {
    const nextValue = {}
    nextValue[name] = value
    dispatch(nextValue)
  }
}

export const initialState: StateType = {
  didUserScroll: false,

  // Search Defaults

  searchParams: {
    origin: 13,
    destination: 42,
    outDate: bookingFromDate(),
    returnDate: null,
    adult: 1,
    concession: 0,
    child: 0,
    youngChild: 0,
    wheelchair: 0,
    bicycle: 0,
  },

  // Other Search Bar Stuff

  searchbarIsCollapsed: true,
  searchModified: false,

  // Book Page Params

  outboundQuotes: null,
  returnQuotes: null,
  outboundBasket: null,
  outboundBasketOrigin: null,
  outboundBasketDestination: null,
  returnBasket: null,
  returnBasketOrigin: null,
  returnBasketDestination: null,
  initialOrder: null,
  initialOrderUid: null,

  // User Info
  loggedIn: null,
  groups: null,
  loggedInPersonUid: null,

  // Customer account
  profile: null,
  balance: null,
  // orders: null,
  shouldRequestAccount: true,
  fetchingAccount: false,

  openLoginResultDialog: false,
  loginErrorMessage: null,
}

setGlobalState(initialState)

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  setGlobalState(state)
  setGlobalDispatch(dispatch)

  // Set didUserScroll parameter once page scrolled
  useEffect(() => {
    if (!state.didUserScroll) {
      // eslint-disable-next-line no-inner-declarations
      function scrolled() {
        window.removeEventListener("scroll", scrolled)
        dispatch({ didUserScroll: true })
      }
      window.addEventListener("scroll", scrolled)
    }
  }, [])

  // Request account when shouldRequestAccount set (including on load)
  useEffect(() => {
    if (state.shouldRequestAccount && !state.fetchingAccount) {
      dispatch({ fetchingAccount: true })
      setTimeout(
        () => {
          Auth.currentUserInfo().then((user) => {
            if (user) {
              Auth.currentSession().then((session) => {
                const payload = session.getAccessToken().payload
                const groups = payload["cognito:groups"]
                  ? payload["cognito:groups"]
                  : []
                dispatch({
                  loggedIn: true,
                  groups: groups,
                  loggedInPersonUid: payload.username,
                })
                const sub = fetchFromAPIBase({
                  path: "/v1/accounts/?profile=true&balance=true",
                  method: "GET",
                  authRequired: true,
                }).subscribe({
                  next: (response) => {
                    if (response && !response.error) {
                      dispatch(response)
                      // Update Front identity
                      if (
                        typeof window !== "undefined" &&
                        window["FrontChat"]
                      ) {
                        window["FrontChat"]("identity", {
                          email: response.profile.email,
                          name: getPersonName(response.profile),
                        })
                      }
                    }
                  },
                  complete: () => {
                    dispatch({
                      shouldRequestAccount: false,
                      fetchingAccount: false,
                    })
                  },
                })
                return () => sub.unsubscribe()
              })
            } else {
              dispatch({
                loggedIn: false,
                groups: null,
                shouldRequestAccount: false,
                fetchingAccount: false,
              })
            }
          })
        },
        window["Cypress"] ? 1000 : 0
      )
    }
  }, [state.shouldRequestAccount])

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
          {children}
        </GlobalDispatchContext.Provider>
      </GlobalStateContext.Provider>
    </QueryClientProvider>
  )
}

/**
 * This is a drop-in replacement for `React.useState` that also caches the state in localStorage or
 * sessionStorage, so it remembers its state between mounts of the components (and, if localStorage
 * is used, across sessions). The value has to be JSON-compatible.
 *
 * This is unrelated to the global state stuff above, but a file called `utils/state-utils.tsx` was
 * really the only logical place to put it.
 */
export function useCachedState<T>(
  cacheKey: string,
  defaultValue: T,
  storage: "localStorage" | "sessionStorage" = "sessionStorage"
): [T, (v: T) => void] {
  const [state, setState] = useState<T>(() => {
    const defaultFromStorage = window[storage].getItem(cacheKey)
    return defaultFromStorage ? JSON.parse(defaultFromStorage) : defaultValue
  })

  useEffect(() => {
    window[storage].setItem(cacheKey, JSON.stringify(state))
  }, [state])

  return [state, setState]
}
